/* Type utilities */
.h0 {
  font-size: $h0-mobile;
  line-height: 1.0125;
  margin-top: 0.85em;
  word-wrap: break-word;

  @media (min-width: $breakpoint-lg) {
    font-size: $h0-desktop;
  }
}

.text-right { text-align: right; }

.no-underline {
  text-decoration: none !important;

  &:hover {
    text-decoration: no-underline !important;
  }
}

.bold { font-weight: bold; }
.uppercase { text-transform: uppercase; }

.lh-condensed { line-height: 1.25; }

.list-reset {
  list-style: none;
  padding-left: 0;
}

/* Border utilities */
.border-bottom-thick { border-bottom: 2px solid; border-color: currentColor; background-color: $gray-back; }
.border-bottom-thin { border-bottom: 1px solid; border-color: currentColor; }
.border-top-thick { border-top: 2px solid; border-color: currentColor; }
.border-top-thin { border-top: 1px solid; border-color: currentColor; }
.border-0 { border: 0; }


/* Theme color utilities */
.header-background {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(255,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23039dc5'/%3E%3Cstop offset='1' stop-color='%23c7394c'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='29' height='29' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='14.5' cy='14.5' r='14.5'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.1'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  ::-moz-selection { /* Code for Firefox */
  color: $gray-back;
  background: $white-txt;
  background-color: $color-background;
  }
  ::selection {
    color: $gray;
    background: $white-txt;
  }
}
.header-border { border-color: $color-foreground; }
.header-title { color: $color-title; }
.header-text { color: $color-foreground; }
.header-social { fill: $color-foreground; }
.header-social-accent { fill: $color-dot-accent; }
.header-social-pink { fill: $color-dot-pink; }
.header-link:hover { color: $color-nav-link !important; } // used for navigation links on homepage
// not used
//.text-accent { color: $color-text-accent; } // used for date in post list and home link
// So the  hat of the logo is slightly higher
.pre-footer { height: 1em; }
footer {
  color: $gray-txt;
}
footer .align-middle { 
  vertical-align: middle;
  display: block;
  line-height: 2.4em;
}
.footer-link {
  color: $gray-txt;
  overflow: hidden;
  white-space: nowrap;
} // used for footer
.footer-link:hover { color: $white-txt; }
.footer-link img { margin-left: 0.5em;margin-right: 0.5em;vertical-align: sub; float: none; }

/* Layout utilities */
.container { max-width: $container-width; }

.col-1 { width: (1 / 12 * 100%); }
.col-2 { width: (2 / 12 * 100%); }
.col-3 { width: (3 / 12 * 100%); }
.col-4 { width: (4 / 12 * 100%); }
.col-5 { width: (5 / 12 * 100%); }
.col-6 { width: (6 / 12 * 100%); }
.col-7 { width: (7 / 12 * 100%); }
.col-8 { width: (8 / 12 * 100%); }
.col-9 { width: (9 / 12 * 100%); }
.col-10 { width: (10 / 12 * 100%); }
.col-11 { width: (11 / 12 * 100%); }
.col-12 { width: 100%; }
//.col-bottom { width: 20%; }

@media (max-width: $breakpoint-lg) {
  .sm-width-full { width: 100% !important; }
  .sm-width-half { width: 50% !important; }
  .sm-width-two-third { width: 66% !important; }
  .sm-width-third { width: 33% !important; }
}
@media (max-width: $breakpoint-sm) {
  .sm-width-full { width: 100% !important; }
  .sm-width-half { width: 100% !important; }
  .sm-width-two-third { width: 100% !important; }
  .sm-width-third { width: 100% !important; }
}

.block        { display: block !important; }
.inline-block { display: inline-block !important; }
.logos { display: flex; flex-wrap: wrap; justify-content: space-between; }
// Fix extra margin on the right side for small devices
.logos img { margin: 10px 5px 10px 5px; }
//.text { display: flex; justify-content: space-between; }
.logos a {
  &:hover img {
    //transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    filter: drop-shadow(0px 2px 2px #111);
    }
  &:focus { filter: drop-shadow(0px 2px 2px #111); }
  //2023-08-02 | &:focus { outline: 0; filter: drop-shadow(0px 2px 2px #111); }
}
//.rss { font-size: 12px; }

.table { display: table !important; }
.left { float: left; }
.right { float: right; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}
.clearfix:after { clear: both }

.align-middle { vertical-align: middle; }

/* Padding */
.px-0 { padding-left: 0; padding-right:  0 }
.py-0 { padding-top: 0;  padding-bottom: 0 }

.px-1 { padding-left: $spacer-1; padding-right:  $spacer-1 }
.py-1 { padding-top: $spacer-1;  padding-bottom: $spacer-1 }

.px-2 { padding-left: $spacer-2; padding-right:  $spacer-2; }
.py-2 { padding-top: $spacer-2;  padding-bottom: $spacer-2; }

.px-3 { padding-left: $spacer-3; padding-right:  $spacer-3; }
.py-3 { padding-top: $spacer-3;  padding-bottom: $spacer-3; }

.px-4 { padding-left: $spacer-4; padding-right:  $spacer-4; }
.py-4 { padding-top: $spacer-4;  padding-bottom: $spacer-4; }

/* Margin */
.mx-auto { margin-left: auto; margin-right: auto; }

.mt-0 { margin-top: 0; }
.mr-0 { margin-right: 0; }
.mb-0 { margin-bottom: 0; }
.ml-0 { margin-left: 0; }

.mt-1 { margin-top: $spacer-1; }
.mr-1 { margin-right: $spacer-1; }
.mb-1 { margin-bottom: $spacer-1; }
.ml-1 { margin-left: $spacer-1; }

.mt-2 { margin-top: $spacer-2; }
.mr-2 { margin-right: $spacer-2; }
.mb-2 { margin-bottom: $spacer-2; }
.ml-2 { margin-left: $spacer-2; }

.mt-3 { margin-top: $spacer-3; }
.mr-3 { margin-right: $spacer-3; }
.mb-3 { margin-bottom: $spacer-3; }
.ml-3 { margin-left: $spacer-3; }

.mt-4 { margin-top: $spacer-4; }
.mr-4 { margin-right: $spacer-4; }
.mb-4 { margin-bottom: $spacer-4; }
.ml-4 { margin-left: $spacer-4; }

// Responsive margin
@media (min-width: 52em) {
  .mx-lg-auto { margin-left: auto; margin-right: auto; }

  .mt-lg-0 { margin-top: 0; }
  .mr-lg-0 { margin-right: 0; }
  .mb-lg-0 { margin-bottom: 0; }
  .ml-lg-0 { margin-left: 0; }

  .mt-lg-1 { margin-top: $spacer-1; }
  .mr-lg-1 { margin-right: $spacer-1; }
  .mb-lg-1 { margin-bottom: $spacer-1; }
  .ml-lg-1 { margin-left: $spacer-1; }

  .mt-lg-2 { margin-top: $spacer-2; }
  .mr-lg-2 { margin-right: $spacer-2; }
  .mb-lg-2 { margin-bottom: $spacer-2; }
  .ml-lg-2 { margin-left: $spacer-2; }

  .mt-lg-3 { margin-top: $spacer-3; }
  .mr-lg-3 { margin-right: $spacer-3; }
  .mb-lg-3 { margin-bottom: $spacer-3; }
  .ml-lg-3 { margin-left: $spacer-3; }

  .mt-lg-4 { margin-top: $spacer-4; }
  .mr-lg-4 { margin-right: $spacer-4; }
  .mb-lg-4 { margin-bottom: $spacer-4; }
  .ml-lg-4 { margin-left: $spacer-4; }
}


// // // // MADE FOR NOMAGIC \\ \\ \\ \\
.highlighter-rouge {
  color: $gray;
  background-color: $gray-highlight;
}
::-moz-selection { /* Code for Firefox */
  color: $white-txt;
  background: $gray;
}
::selection {
  color: $white-txt;
  background: $gray;
}

// Link styles for social icons
.link-social {
  text-decoration: none;
  font-weight: bold;
  line-height: 1;

  &:hover {
    text-decoration: none !important;
  }
}

// Link styles for navigation
.link-primary {
  font-weight: bold;
  //text-decoration: none;

  &:hover {
    color: $color-hover-link;
    //color: $color-primary-link;
    text-decoration: none;
  }
}

// CC ttf loading
.licensing {
  background-color: $cc-banner;
  color: $white-txt;
  font-size: $licensing-font-size;
  text-align: center;
  clear: both;
}
.link-licensing:hover { color: $white-txt; }
.link-licensing { color: $gray-txt; }

@media screen {
  @font-face {
    font-family: 'CC-ICONS';
    font-style: normal;
    font-weight: normal;
    src: url('fonts/cc-icons.ttf') format('truetype');
  }
  span.cc {
    font-family: 'CC-ICONS';
    display: inline-block;
    line-height: 22px;
    //width: 100%;
  }
}


// Custom 20180315 - Indicator
#status { color: white; }

@font-face {
    font-family: 'Montserrat-bold';
    src: url('fonts/Montserrat/Montserrat-Bold.woff2') format('woff2');
}
// Punchline
.punchline {
    font-family: 'Open Sans';
    font-size: 1.2em;
    line-height: 1.6em;
}
.punchline .highlight  {
    font-family: 'Montserrat-bold';
}
.sp-container {
    clear: both;
    font-family: 'Montserrat-bold';
}
.sp-container h1 {
    font-size: 1.8em;
    width: 100%;
    color: transparent;
    -webkit-animation: blurFadeInOut 3s ease-in backwards;
    -moz-animation: blurFadeInOut 3s ease-in backwards;
    -ms-animation: blurFadeInOut 3s ease-in backwards;
    animation: blurFadeInOut 3s ease-in backwards;
}
.sp-container h1.frame-5 {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
    color: transparent;
    text-shadow: 0px 0px 1px #000;
    text-align: left;
}
.sp-container h1.frame-5 span {
    -webkit-animation: blurFadeIn 3s ease-in 6s backwards;
    -moz-animation: blurFadeIn 1s ease-in 6s backwards;
    -ms-animation: blurFadeIn 3s ease-in 6s backwards;
    animation: blurFadeIn 3s ease-in 6s backwards;
    color: transparent;
    text-shadow: 0px 0px 0px #fff;
    float: none;
}
.sp-container h1.frame-5 span:nth-child(1) {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -ms-animation-delay: 0s;
    animation-delay: 0s;
    text-shadow: 0px 0px 0px #fff, 1px 1px 1px #000;
    //text-shadow: 0px 0px 0px #bc3a50, 1px 1px 1px #000;
}
.sp-container h1.frame-5 span:nth-child(2) {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -ms-animation-delay: 1s;
    animation-delay: 1s;
    text-shadow: 0px 0px 0px #fff, 1px 1px 1px #000;
}
.sp-container h1.frame-5 span:nth-child(3) {
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -ms-animation-delay: 2s;
    animation-delay: 2s;
    text-shadow: 0px 0px 0px #fff, 1px 1px 1px #000;
    //text-shadow: 0px 0px 0px #219cc7, 1px 1px 1px #000;
}

/**/

@-webkit-keyframes blurFadeInOut {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 40px #fff;
        -webkit-transform: scale(1.3);
    }
    20%, 75% {
        opacity: 1;
        text-shadow: 0px 0px 1px #fff;
        -webkit-transform: scale(1);
    }
    100% {
        opacity: 0;
        text-shadow: 0px 0px 50px #fff;
        -webkit-transform: scale(0);
    }
}
@-webkit-keyframes blurFadeIn {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 40px #fff;
        -webkit-transform: scale(1.3);
    }
    50% {
        opacity: 0.5;
        text-shadow: 0px 0px 10px #fff;
        -webkit-transform: scale(1.1);
    }
    100% {
        opacity: 1;
        text-shadow: 0px 0px 1px #fff;
        -webkit-transform: scale(1);
    }
}
/**/

@-moz-keyframes blurFadeInOut {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 40px #fff;
        -moz-transform: scale(1.3);
    }
    20%, 75% {
        opacity: 1;
        text-shadow: 0px 0px 1px #fff;
        -moz-transform: scale(1);
    }
    100% {
        opacity: 0;
        text-shadow: 0px 0px 50px #fff;
        -moz-transform: scale(0);
    }
}
@-moz-keyframes blurFadeIn {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 40px #fff;
        -moz-transform: scale(1.3);
    }
    100% {
        opacity: 1;
        text-shadow: 0px 0px 1px #fff;
        -moz-transform: scale(1);
    }
}
/**/

@keyframes blurFadeInOut {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 40px #fff;
        transform: scale(1.3);
    }
    20%, 75% {
        opacity: 1;
        text-shadow: 0px 0px 1px #fff;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        text-shadow: 0px 0px 50px #fff;
        transform: scale(0);
    }
}
@keyframes blurFadeIn {
    0% {
        opacity: 0;
        text-shadow: 0px 0px 40px #fff;
        transform: scale(1.3);
    }
    50% {
        opacity: 0.5;
        text-shadow: 0px 0px 10px #fff;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        text-shadow: 0px 0px 1px #fff;
        transform: scale(1);
    }
}

// Hit the floor
.hit-the-floor {
    color: #fff;
    font-size: 0.1em;
    width: 80%;
    //font-weight: bold;
    //font-family: Helvetica;
    //text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
    box-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
}


// Ribbon
@function lower-bound($range){
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range,1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// Icon Settings
$ribbon-icon-bg-color: #000 !default;
$ribbon-hover-bg-color: #BC3A50 !default;
$ribbon-icon-x-position: 5px !default;
$ribbon-icon-y-position: 5px !default;
$ribbon-icon-width: 42px !default;
$ribbon-icon-height: 42px !default;

// Banner Settings
$ribbon-font-stack: "Open Sans", sans-serif !default;
$ribbon-banner-wrapper-width: 250px !default;
$ribbon-banner-wrapper-height: $ribbon-banner-wrapper-width !default;
$ribbon-banner-wrapper-z-index: 9999 !default;
$ribbon-banner-bg-color: $ribbon-icon-bg-color !default;
$ribbon-banner-padding: 2px 0 !default;
$ribbon-banner-box-shadow: 0 0 10px 0 #888 !default;
$ribbon-banner-x-position: -80px !default;
$ribbon-banner-y-position: 45px !default;
$ribbon-banner-text-color: #fff !default;
$ribbon-banner-text-shadow: 0 0 5px #444 !default;
$ribbon-banner-text-padding: 10px 50px !default;
$ribbon-banner-text-border: 1px solid #fff !default;

// Media Query Ranges
$tiny-range: (0em, 26em) !default; // 0, 400px
$small-range: (26.0630em, 44.750em) !default; // 417px, 716px
$medium-range: (44.813em, 64em) !default; // 717px, 1024px
$large-range: (64.063em, 90em) !default; // 1025px, 1440px
$xlarge-range: (90.063em, 120em) !default; // 1441px, 1920px
$xxlarge-range: (120.063em, 99999999em) !default; // 1921px

$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

// JY | added 2019-10-24 for fediverse share
$tiny-up: $screen !default;
$tiny-only: "#{$screen} and (max-width: #{upper-bound($tiny-range)})" !default;

$small-up: "#{$screen} and (min-width:#{lower-bound($small-range)})" !default;
$small-only: "#{$screen} and (min-width: #{lower-bound($small-range)}) and (max-width:#{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

// Not showing ribbon if menu cannot be displayed
.ribbon-me-wrapper {
    @media #{$tiny-only} {
        display: none !important;
    }
    @media #{$small-only} {
        display: none !important;
    }
    @media #{$medium-only} {
        display: none !important;
    }
    width: $ribbon-banner-wrapper-width;
    height: $ribbon-banner-wrapper-height;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: $ribbon-banner-wrapper-z-index;
}

.ribbon-me {
    width: $ribbon-icon-width;
    height: $ribbon-icon-height;
    position: absolute;
    top: $ribbon-icon-y-position;
    right: $ribbon-icon-x-position;
    background-color: $ribbon-icon-bg-color;
    //background-image: url($ribbon-icon-light);
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    white-space: nowrap;

    @media #{$medium-up} {
        width: auto;
        height: auto;
        top: $ribbon-banner-y-position;
        right: $ribbon-banner-x-position;
        padding: $ribbon-banner-padding;
        box-shadow: $ribbon-banner-box-shadow;
        background-color: $ribbon-banner-bg-color;
        background-image: none !important;
        transform: rotate(45deg);
    }
}

.ribbon-me-link {
    display: block;
    height: 100%;
    text-decoration: none;
    border: none;

    @media #{$medium-up} {
        font-family: $ribbon-font-stack;
        letter-spacing: 2px;
        text-align: center;
        color: $ribbon-banner-text-color;
        padding: $ribbon-banner-text-padding;
        border: $ribbon-banner-text-border;
        text-shadow: $ribbon-banner-text-shadow;
    }

    &:link,
    &:visited,
    &:focus,
    &:active {
        @extend .ribbon-me-link;
    }
    &:hover {
        background: $ribbon-hover-bg-color;
    }

    // Aid accessibility.
    &:focus {
        text-decoration: underline;
    }
}

.ribbon-me-text {
    display: none;

    @media #{$medium-up} {
        display: inline;
    }
}

.ribbon-me-dark {
    //background-image: url($ribbon-icon-dark);
}

.ribbon-me-left {
    left: 0;
    right: auto;

    .ribbon-me {
        left: $ribbon-icon-x-position;
        right: auto;

        @media #{$medium-up} {
            left: $ribbon-banner-x-position;
            right: auto;
            transform: rotate(-45deg);
        }
    }
}

.ribbon-me-fixed {
    position: fixed;
}

// Header Menu for services
.menu_block {
    display: inline-block !important;
    color: $gray;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}
.menu_block img {
    margin-right: 0.4em;
    margin-left: 0;
}
//.menu_block img {
//    margin-right: 0.4em;
//    margin-left: 0;
//}
//.menu_block:hover {
//    color: white;
//    background-color: $pink;
//    border: 1px solid;  border-color: $pink;
//}
.menu_block_label_no_link {
  display: inline-block !important;
  color: $gray;
  border: 1px solid; border-color: $blue;
  border-radius: 5px;
  //margin-bottom: 0.2em;
  padding: 0.4em;
  line-height: 2em;
}
.menu_block_label {
  display: inline-block !important;
  color: $gray;
  border: 1px solid; border-color: $blue;
  border-radius: 5px 0px 0px 5px;
  //margin-bottom: 0.2em;
  padding: 0.4em;
  line-height: 2em;
  //font-size: 0.9em;
  float: left;
}
.menu_block_label:hover, .menu_block_label_no_link:hover {
  color: $white;
  background-color: $pink;
  border: 1px solid; border-color: $pink;
}
.menu_block_link {
  display: inline-block !important;
  border-top: 1px solid; border-color: $blue;
  border-bottom: 1px solid; border-color: $blue;
  border-right: 1px solid; border-color: $blue;
  border-radius: 0px 5px 5px 0px;
  background-color: $gray-highlight;
  line-height: 2.8em;
  svg {
      fill: $gray;
      height: 1.2em;
      width: 2.0em;
      vertical-align: middle;
  }
}
.menu_block_link:hover {
  color: white;
  background-color: $pink;
  border-top: 1px solid; border-color: $pink;
  border-bottom: 1px solid; border-color: $pink;
  border-right: 1px solid; border-color: $pink;
  svg {
      fill: $white;
  }
}
.service_link {
  display: inline-block !important;
  border: 1px solid; border-color: $blue;
  border-radius: 5px;
  background-color: $gray-highlight;
  line-height: 1.0em;
  margin-left: 10px;
  svg {
      fill: $gray;
      height: 1.0em;
      width: 1.0em;
      padding: 2px;
      vertical-align: middle;
  }
}
.service_link:hover {
  color: white;
  background-color: $pink;
  border: 1px solid; border-color: $pink;
  svg {
      fill: $white;
  }
}

// search form
#search-input {
    padding: 8px 15px;
    margin: 5px 1px 3px 0px;
    border: none;
    width: 100%;
    border-radius: 1px;
    box-shadow: 0 1px 0px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
}
// top link
.link-to-top img {
  //filter: drop-shadow(0px 0px 1px $blue);
  &:hover {
    filter: drop-shadow(0px 0px 1px $pink);
  }
}
// Notes
.note { 
  padding: 8px 8px 8px 12px;
  border-radius: 0px 5px 5px 0px;
  margin-top: $spacer-3 !important;
  margin-bottom: $spacer-3 !important;
  clear: both;
}
// small note with no margins
.footnote { 
  padding: 8px 8px 8px 12px;
  border-radius: 0px 5px 5px 0px;
}
.danger {
  background-color: #ffdddd;
  border-left: 6px solid #f44336;
  filter: drop-shadow(0px 0px 1px #f44336);
}
.success {
  background-color: #ddffdd;
  border-left: 6px solid #4CAF50;
  filter: drop-shadow(0px 0px 1px #4CAF50);
}
.info {
  background-color: #e7f3fe;
  border-left: 6px solid #2196F3;
  filter: drop-shadow(0px 0px 1px #2196F3);
}
.warning {
  background-color: #ffe8cc;
  border-left: 6px solid #ffa93b;
  filter: drop-shadow(0px 0px 1px #ffa93b);
}
// emoji plugin
img.emoji {
  float: none;
  margin: 0 0 0 5px;
  vertical-align: middle;
  height: 1.2em;
  width: 1.2em;
}

/* ------------- */
/* SHARE BUTTONS */
/* ------------- */

#share-buttons > a > svg {
  border: 8px solid #000;
  height: 3.4em;
  width: 3.4em;
  float: left;
  border: 8px solid transparent;
  fill: #12ACE9; }
#share-buttons > a:hover > svg {
  cursor: pointer;
  background: #F94A96;
  fill: #fff; }
#share-icon:after {
  content: "";
  display: block;
  clear: both; }
#share-icon {
  height: 3.4em;
  width: 3.4em;
  border: 8px solid transparent;
  position: relative;
  float: left; }
  
.popup-container .popup-button > div {
  position: relative;
  float: left; }
.popup-container .popup-button > div > svg {
  height: 3.4em;
  width: 3.4em;
  border: 8px solid transparent;
  fill: #12ACE9;
  }
.popup-container .popup-button > div:hover > svg {
  cursor: pointer;
  background: #F94A96;
  fill: #fff; }
.popup-container .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: 250ms all; }
  .popup-container .popup .popup-inner {
    box-sizing: border-box;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 150%;
    transition: 250ms all;
    text-align: left;
    border-radius: 10px; }
    @media only screen and (max-width: 32em) {
      .popup-container .popup .popup-inner {
        width: 100%; } }
    @media only screen and (min-width: 32em) and (max-width: 44.813em) {
      .popup-container .popup .popup-inner {
        width: 70%; } }
    @media only screen and (min-width: 44.813em) {
    .popup-container .popup .popup-inner {
      width: 400px; } }
    .popup-container .popup .popup-inner .popup-title {
      padding: 15px 15px 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      border-bottom: solid #b5b5b5;
      border-bottom-width: thin; }
      .popup-container .popup .popup-inner .popup-title h1 {
        font-size: 1.2em;
        margin-top: 0; }
      .popup-container .popup .popup-inner .popup-title .popup-close-btn {
        cursor: pointer;
        background: #45474A;
        opacity: .6;
        font-weight: bold;
        display: block;
        line-height: 24px;
        padding: 0 15px;
        font-size: 14px;
        color: #fff;
        border-radius: 3px; }
      .popup-container .popup .popup-inner .popup-title .popup-close-btn:hover {
        background: #45474A;
        opacity: 1;
        color: #fff; }
    .popup-container .popup .popup-inner .popup-footer {
      margin-top: 10px;
      padding: 15px;
      float: right;
      font-weight: bold; }
      .popup-container .popup .popup-inner .popup-footer .popup-share-btn {
        cursor: pointer;
        font-weight: bold;
        font-size: 1.0em;
        line-height: 3em;
        background: #12ACE9;
        padding: 0 15px 0 15px;
        color: #f5f5f5;
        border-radius: 3px;
        border: 1px solid transparent; }
      .popup-container .popup .popup-inner .popup-footer .popup-share-btn:hover {
        background: #F94A96; }
      .popup-container .popup .popup-inner .popup-footer .popup-close-btn {
        cursor: pointer;
        color: #f5f5f5;
        background: #45474A;
        opacity: .6;
        line-height: 3em;
        padding: 0 15px 0 15px;
        margin-right: 5px;
        border-radius: 3px;
        display: inline-block;
        border: 1px solid transparent; }
      .popup-container .popup .popup-inner .popup-footer .popup-close-btn:hover {
        opacity: 1;
        background: #45474A; }
    .popup-container .popup .popup-inner .popup-content {
      padding: 15px; }
      .popup-container .popup .popup-inner .popup-content input {
        border: 1px solid #ddd;
        border-radius: 3px;
        line-height: 34px;
        padding: 0 15px;
        font-size: 14px;
        box-sizing: border-box; }
      .popup-container .popup .popup-inner .popup-content input[type=text]:focus {
        border-color: #45474A; }
  .popup-container .popup .transparent-label {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: pointer; }
.popup-container > input {
  display: none; }
  .popup-container > input:checked + .popup {
    opacity: 1;
    visibility: visible; }
    .popup-container > input:checked + .popup .popup-inner {
      top: 50%; }
.form-control {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 10px; }
.form-check-radio {
  margin-top: 10px; }
  .form-check-radio li {
    list-style: none; }

// social follow buttons
.follow-buttons {position: relative; float: left; text-align: center; margin: 10px 15px 0 0;}
.follow-buttons > svg {height: 2.4em; width: 2.4em; fill: $gray-txt;}
.follow-buttons:hover {cursor: pointer; background-color: $gray-back;border-radius:50%}
.follow-buttons:hover > svg {fill: $white;}

// homepage icons only on big screen
.home-illustrate {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  // Fix extra margin on the right side for small devices
  img {
    display: flex;
    max-width: 80%;
  }
  @media #{$tiny-only} {
    display: none !important;
  }
  @media #{$small-only} {
    display: none !important;
  }
  @media #{$medium-only} {
    display: none !important;
  }
}
.home-logo {
  max-width: 33%;
  @media #{$tiny-only} {
    display: none !important;
  }
  @media #{$small-only} {
    max-width: 40%;
  }
}
.home-top {
  padding: 0.8em;
  background-color: $gray-back;
  //opacity: 0.9;
  border-radius: 15px;
  margin-bottom: 4em;
}

.footer-top {
  //min-height: 120px;
  min-width: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-fed-lang { display: flex; flex-wrap: wrap; justify-content: space-between; }
.antimagnet { flex: content; }

.custom-shape-divider-top-1598710580 {
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}
.custom-shape-divider-top-1598710580 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 70px;
}
.custom-shape-divider-top-1598710580 .shape-fill {
    fill: #f5f5f5;

}
.welcome {
  display: inline-block;
}
.welcome img {
  display: inline-block;
  height: 48px;
  float: none;
  vertical-align: middle;
  margin-left: 0.2em;
  margin-right: 0.1em;
}
