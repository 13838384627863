.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: $gray-back;

    // // fixed position
    //position: fixed;
    //top: 0;
    width: 100%;
    box-shadow: 0px 2px 4px black;
}
.menu li {
    float: left;
}
.menu li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}
.menu li a:hover {
    background-color: #12ACE9;
    color: white;
}
.menu .active {
    background-color: $pink-nomagic;
}
.main-content {
  margin-top: 2em;
}



/**
 * Site header
 */
.site-header {
    background-color: $gray;
}

.site-title {
//  @include relative-font-size(1.625);
//  font-weight: 300;
//  line-height: $base-line-height * $base-font-size * 2.25;
//  letter-spacing: -1px;
//  margin-bottom: 0;
//  float: left;

  &,
  &:visited {
    color: $gray;
  }
}

// Sandwich menu on small screens
.site-nav {
  position: relative;
  .nav-trigger {
      display: none;
  }
  .menu-icon {
    display: none;
  }
  .menu-bar {
    display: none;
  }
  .page-link {
  }
  @include media-query($on-palm) {
    height:36px;
    //position: absolute;
    //top: 9px;
    //right: $spacing-unit / 2;
    background-color: $gray;
    //border: 1px solid $gray;
    //border-radius: 5px;
    //text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 100%;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }
    .menu-bar {
      display: block;
      //width: 100%;
      .menu-icon {
        display: block;
        float: right;
        width: 36px;
        height: 26px;
        line-height: 0;
        padding-top: 10px;
        text-align: center;

        > svg path {
          fill: $white;
        }
      }
    }
    input ~ .trigger {
      clear: both;
      display: none;
    }
    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }
    .page-link {
    }
  }
}
