// Blue theme

// Color variables
$blue: #12ACE9;
$pink: #F94A96;
$pink-nomagic: #BC3A50;
// for reference
//$blue-nomagic: #219cc7;
$blue-small: #007AB3;
$gray: #45474A;
$gray-back: #2e3033;
$gray-highlight: #e0e0e0;
$gray-txt: #b5b5b5;
$white: #fff;
$white-txt: #f5f5f5;
$cc-banner: #666666;


$spacing-unit:     30px !default;
$background-color: #fdfdfd !default;
$on-palm:          695px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}



// Config
$color-background: $gray !default;
$color-dot-accent: $blue !default;
$color-dot-pink: $pink !default;
$color-foreground: $white !default;
$color-title: $blue !default;
$color-body-text: $gray !default;
$color-text-accent: $gray !default;
$color-code: $blue-small !default;
$color-nav-link: $blue !default;
$color-primary-link: $blue !default;
$color-hover-link: $pink !default;

.font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Import sass partials (used in all themes)
@import
        "variables",
        "base",
        "components",
        "utilities",
        "menu"
;
